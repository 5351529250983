html, body {
  height: 100vh;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: fixed;
  width: 100%;
}

/* Only display headline and divider if .detailContent is not empty. Has to be first child in order to use sibling selector */
.detailBlock .detailHeadline,
.detailBlock .detailDivider {
  display: none;
}

.detailBlock .detailContent:not(:empty) ~ .detailHeadline,
.detailBlock .detailContent:not(:empty) ~ .detailDivider {
  display: block;
}

.activeMarker {
  animation: pulse 2s infinite;
}

.markerClassIE {
  position: relative;
  top: 1px;
  left: -16px;
}

.icon-ie11-active:before {
  color: #fff;
  position: relative;
  left: 42px;
  top: -18px;
  font-size: 32px;
}

.icon-ie11-active-badge:before {
  color: #fff;
  position: relative;
  left: 10px;
  top: 38px;
  font-size: 32px;
}

.icon-ie11-pin:before {
  color: #fff;
  position: relative;
  left: 35px;
  top: -12px;
  font-size: 28px;
}

.icon-ie11-pin-badge:before {
  color: #fff;
  position: relative;
  left: 7px;
  top: 33px;
  font-size: 28px;
}

.markerClassIE-circle.icon-bus:before {
  display: none;
}

.markerClassIE-circle {
  position: relative;
  top: 1px;
  left: -16px;
}

.markerClassIE-circle-badge {
  position: relative;
  top: 1px;
  left: -16px;
}

.icon-ie11-pin.icon-publictransport:before,
.icon-ie11-circle.icon-publictransport:before,
.icon-ie11-active.icon-publictransport:before {
  color: rgb(0, 135, 84);
}

.icon-ie11-circle:before {
  color: #fff;
  position: relative;
  left: 20px;
  top: -5px;
  font-size: 16px;
}

.icon-ie11-circle-badge:before {
  color: #fff;
  position: relative;
  left: 4px;
  top: 19px;
  font-size: 16px;
}

.markerClass {
  position: relative;
}

/*
 * Remove the rubberBand
 * https://stackoverflow.com/questions/29008194/disabling-androids-chrome-pull-down-to-refresh-feature/47709903#47709903
 */
body {
  overscroll-behavior: contain;
}

@-webkit-keyframes swipeUnlock {
  0% {
    margin-top: 0;
  }
  50% {
    margin-top: 20px;
  }
  100% {
    margin-top: 0;
  }
}

.swipeUnlockAnimation{
  transition:  swipeUnlock 1s;
}

@-webkit-keyframes pulse {
  0% {
    filter: drop-shadow( 0px 0px 4px rgba(0, 0, 0, 0.6) );
  }
  70% {
    filter: drop-shadow( 0px 0px 2px rgba(0, 0, 0, 0.7) );
  }
  100% {
    filter: drop-shadow( 0px 0px 4px rgba(0, 0, 0, 0.6) );
  }
}

/* Fixes for IE11 */
@media all and (-ms-high-contrast:none) {
  *::-ms-backdrop, :root #bottomNavigation button { max-width: none; }
}


@media (min-width: 960px) {
  .mapboxgl-ctrl.mapboxgl-ctrl-group {
    border-radius: 3px;
  }
  .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl .mapboxgl-ctrl-compass {
    display: none;
  }

  /* Set margins for bottom-right controls. */
  .mapboxgl-control-container .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl,
  .mapboxgl-control-container .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-group {
    margin-bottom: 8px;
    margin-right: 7px;
  }

  .mapboxgl-ctrl .mapboxgl-ctrl-icon.mapboxgl-ctrl-zoom-in,
  .mapboxgl-ctrl .mapboxgl-ctrl-icon.mapboxgl-ctrl-zoom-out {
    background-image: none;
  }

  .mapboxgl-ctrl .mapboxgl-ctrl-icon.mapboxgl-ctrl-zoom-in:after {
    content: '+';
    font-size: 36px;
    color: rgba(0,0,0,0.37);
    position: relative;
    top: -5px;
  }

  .mapboxgl-ctrl .mapboxgl-ctrl-icon.mapboxgl-ctrl-zoom-out:after {
    content: '-';
    color: rgba(0,0,0,0.37);
    font-size: 50px;
    position: relative;
    top: -15px;
  }

  .mapboxgl-ctrl.mapboxgl-ctrl-group > button {
    width: 40px;
    height: 40px;
  }

  .mapboxgl-ctrl-group:not(:empty) {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.37);
  }

  .mapboxgl-control-container .mapboxgl-ctrl-bottom-left {
    z-index: 23;
  }

}

/*
 * XXX: Not setable in JSS ?!
 * (ﾉ °益°)ﾉ 彡 ┻━┻
 */
input::-webkit-input-placeholder {
  color: inherit;
}

/*
 * TODO: Remove from Schuldenberg
 */
.webfonticon {
  overflow: visible !important;
}
